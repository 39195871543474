import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
	EnvelopeFill,
	FileEarmarkFill,
	FileEarmarkTextFill,
	Linkedin,
} from 'react-bootstrap-icons';

import Image from 'react-bootstrap/Image';

function Profile(): React.ReactElement {
	return (
		<Container className="profile">
			<Row>
				<Col className="profile-picture">
					<Image src={`${process.env.PUBLIC_URL}assets/profile/jerry-profile.jpg`} alt="Jerry Profile" roundedCircle />
				</Col>
			</Row>

			<Row>
				<Col className="profile-social">
					<a href="mailto:jerry.yeoh1270@gmail.com" className="icon email">
						<EnvelopeFill size={42} />
					</a>

					<a href="https://www.linkedin.com/in/jerry-yeoh-aa6a872" target="_blank" className="icon linkedin" rel="noreferrer">
						<Linkedin size={35} />
					</a>
				</Col>
			</Row>

			<Row>
				<Col className="profile-description">
					<h2>About Me</h2>
					<p>Jerry Yeoh has worked in print for over 15 years. He is highly skilled in all levels of production from imaging to layout to preflighting to troubleshooting. He is both a conceptual and analytical problem solver who strives for funtional design aesthetics.</p>
					<p>In addition to his graphic design and production skills, Jerry brings a focused commitment to all the projects he is involved in and has proven himself an invaluable asset time and again. He is adaptive and can work within creative style guides.</p>
					<p>Jerry has enjoyed working with clients in various industries, from consumer packaged goods companies to retailers and small businesses. He fits into a variety of corporate structures providing different levels of creative solutions in communications, production management and design-related issues.</p>
				</Col>
			</Row>

			<Row>
				<Col className="profile-cv">
					<span className="downloads-heading">Download my</span>
					<a href="assets/files/JerryYeoh_resume_041016.pdf" target="_blank" className="icon phone" rel="noreferrer">
						<FileEarmarkTextFill size={42} /> Resume
					</a>

					<a href="assets/files/jerry-yeoh-portfolio.pdf" target="_blank" className="icon phone" rel="noreferrer">
						<FileEarmarkFill size={42} /> Portfolio
					</a>				
				</Col>
			</Row>

			<div className="dot-separator"></div>
		</Container>
	);
}

export default Profile;