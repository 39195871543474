import Footer from './layout/Footer';
import Header from './layout/Header';
import Portfolio from './portfolio/Portfolio';
import Profile from './profile/Profile';

function App(): React.ReactElement {
  return (
    <div>
      <Header />
      <Profile />
      <Portfolio />
      <Footer />
    </div>
  );
}

export default App;
