export const portfolio = [
	{
		subtitle: 'Click to view flats',
		category: 'packaging latest-work',
		thumbnail: 'assets/portfolio/packaging/us_paella_bowl_3d.jpg',
		image: 'assets/portfolio/packaging/us_paella_bowl_flat.png',
	},
	{
		subtitle: 'Click to view flats',
		category: 'packaging latest-work',
		thumbnail: 'assets/portfolio/packaging/us_mex_breakfast_bake_3d.jpg',
		image: 'assets/portfolio/packaging/us_mex_breakfast_bake_flat.png',
	},
	{
		subtitle: 'Click to view flats',
		category: 'packaging',
		thumbnail: 'assets/portfolio/packaging/amys_sonoma_burger_3d.png',
		image: 'assets/portfolio/packaging/amys_sonoma_burger_flat.jpg',
	},
	{
		subtitle: 'Click to view flats',
		category: 'packaging',
		thumbnail: 'assets/portfolio/packaging/amys_broc_cheddar_3d.png',
		image: 'assets/portfolio/packaging/amys_broc_cheddar_flat.jpg',
	},
	{
		subtitle: 'Click to view flats',
		category: 'packaging',
		thumbnail: 'assets/portfolio/packaging/amys_chinese_noodle_3d.png',
		image: 'assets/portfolio/packaging/amys_chinese_noodle_flat.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'ads',
		thumbnail: 'assets/portfolio/ads/amys_ocado_uk_ad.jpg',
		image: 'assets/portfolio/ads/amys_ocado_uk_ad.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'ads',
		thumbnail: 'assets/portfolio/ads/amys_gfm_ad.jpg',
		image: 'assets/portfolio/ads/amys_gfm_ad.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'print',
		thumbnail: 'assets/portfolio/print/amys_meals_sellsheet.jpg',
		image: 'assets/portfolio/print/amys_meals_sellsheet.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'ads',
		thumbnail: 'assets/portfolio/ads/birdseye_dko-ads.jpg',
		image: 'assets/portfolio/ads/birdseye_dko-ads.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'ads',
		thumbnail: 'assets/portfolio/ads/coke_game-ads.jpg',
		image: 'assets/portfolio/ads/coke_game-ads.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'ads',
		thumbnail: 'assets/portfolio/ads/nestle_pie-ads.jpg',
		image: 'assets/portfolio/ads/nestle_pie-ads.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'ads',
		thumbnail: 'assets/portfolio/ads/orville_coke-ads.jpg',
		image: 'assets/portfolio/ads/orville_coke-ads.jpg',
	},
	{
		subtitle: '',
		description: 'Click to see back of coupon',
		category: 'pos latest-work',
		thumbnail: 'assets/portfolio/pos/us_5vip_coupon_front.png',
		image: 'assets/portfolio/pos/us_5vip_coupon_back.png',
	},
	{
		subtitle: '',
		description: 'Click to see back of coupon',
		category: 'pos latest-work',
		thumbnail: 'assets/portfolio/pos/us_10vip_coupon_front.png',
		image: 'assets/portfolio/pos/us_10vip_coupon_back.png',
	},
	{
		subtitle: '',
		description: '',
		category: 'pos',
		thumbnail: 'assets/portfolio/pos/asiangourmet-pos.jpg',
		image: 'assets/portfolio/pos/asiangourmet-pos.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'pos',
		thumbnail: 'assets/portfolio/pos/lawry_bbq-pos.png',
		image: 'assets/portfolio/pos/lawry_bbq-pos.png',
	},
	{
		subtitle: '',
		description: '',
		category: 'pos',
		thumbnail: 'assets/portfolio/pos/nestle_pie-pos.jpg',
		image: 'assets/portfolio/pos/nestle_pie-pos.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'pos',
		thumbnail: 'assets/portfolio/pos/stouffer_coke-pos.jpg',
		image: 'assets/portfolio/pos/stouffer_coke-pos.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'display latest-work',
		thumbnail: 'assets/portfolio/display/woolworth_soup_display_comp.png',
		image: 'assets/portfolio/display/woolworth_soup_display_mockup.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'display',
		thumbnail: 'assets/portfolio/display/amys_candy_display_shipper.jpg',
		image: 'assets/portfolio/display/amys_candy_display_shipper.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'display',
		thumbnail: 'assets/portfolio/display/cheezit_coke-display.png',
		image: 'assets/portfolio/display/cheezit_coke-display.png',
	},
	{
		subtitle: '',
		description: '',
		category: 'display',
		thumbnail: 'assets/portfolio/display/emerald_coke-display.png',
		image: 'assets/portfolio/display/emerald_coke-display.png',
	},
	{
		subtitle: '',
		description: '',
		category: 'display',
		thumbnail: 'assets/portfolio/display/lawrys_chips-display.png',
		image: 'assets/portfolio/display/lawrys_chips-display.png',
	},
	{
		subtitle: '',
		description: '',
		category: 'display',
		thumbnail: 'assets/portfolio/display/orville_coke-display.png',
		image: 'assets/portfolio/display/orville_coke-display.png',
	},
	{
		subtitle: '',
		description: '',
		category: 'digital',
		thumbnail: 'assets/portfolio/digital/banners1.jpg',
		image: 'assets/portfolio/digital/banners1.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'digital',
		thumbnail: 'assets/portfolio/digital/burlap-site.jpg',
		image: 'assets/portfolio/digital/burlap-site.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'digital',
		thumbnail: 'assets/portfolio/digital/ecocentric-site.jpg',
		image: 'assets/portfolio/digital/ecocentric-site.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'digital',
		thumbnail: 'assets/portfolio/digital/solano-site.jpg',
		image: 'assets/portfolio/digital/solano-site.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'print latest-work',
		thumbnail: 'assets/portfolio/print/us_c-store_snacks_sellsheet.png',
		image: 'assets/portfolio/print/us_c-store_snacks_sellsheet.png',
	},
	{
		subtitle: '',
		description: '',
		category: 'print latest-work',
		thumbnail: 'assets/portfolio/print/us_c-store_chili_sellsheet.png',
		image: 'assets/portfolio/print/us_c-store_chili_sellsheet.png',
	},
	{
		subtitle: '',
		description: '',
		category: 'print latest-work',
		thumbnail: 'assets/portfolio/print/wholefoods_freezer_blade.jpg',
		image: 'assets/portfolio/print/wholefoods_freezer_blade.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'print latest-work',
		thumbnail: 'assets/portfolio/print/candy_shipper_instruction.png',
		image: 'assets/portfolio/print/candy_shipper_instruction.png',
	},
	{
		subtitle: '',
		description: '',
		category: 'print',
		thumbnail: 'assets/portfolio/print/print-coke1.jpg',
		image: 'assets/portfolio/print/print-coke1.jpg',
	},
	{
		subtitle: '',
		description: '3-foot high poster series for an Epiphany Center Dinner/Fundraiser to help raise awareness and funding.',
		category: 'print',
		thumbnail: 'assets/portfolio/print/print-posters6.jpg',
		image: 'assets/portfolio/print/print-posters6.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'print',
		thumbnail: 'assets/portfolio/print/print-sacredheart.jpg',
		image: 'assets/portfolio/print/print-sacredheart.jpg',
	},
	{
		subtitle: '',
		description: 'This Spa Certificate was for the national sales force and their significant others at a company\'s sales and brain-storming event.',
		category: 'print',
		thumbnail: 'assets/portfolio/print/print-spa3.jpg',
		image: 'assets/portfolio/print/print-spa3.jpg',
	},
	{
		subtitle: '',
		description: 'ID design for a clothing company using burlap material for their clothing.',
		category: 'id',
		thumbnail: 'assets/portfolio/id/id-burlap4.jpg',
		image: 'assets/portfolio/id/id-burlap4.jpg',
	},
	{
		subtitle: '',
		description: 'ID design for an architecture company specializing in using materials that are sustainable and eco-friendly.',
		category: 'id',
		thumbnail: 'assets/portfolio/id/id-ecocentric1.jpg',
		image: 'assets/portfolio/id/id-ecocentric1.jpg',
	},
	{
		subtitle: '',
		description: 'ID design for a studio specializing in photographing and videographing special events and providing CD/DVDs for those special memories.',
		category: 'id',
		thumbnail: 'assets/portfolio/id/id-giggles2.jpg',
		image: 'assets/portfolio/id/id-giggles2.jpg',
	},
	{
		subtitle: '',
		description: 'ID design for a Colorado-based bistro which specializes in brick-oven pizzas.',
		category: 'id',
		thumbnail: 'assets/portfolio/id/id-rustic_over3.jpg',
		image: 'assets/portfolio/id/id-rustic_over3.jpg',
	},
	{
		subtitle: '',
		description: 'Portrait of a friend for his graduation card and poster.',
		category: 'fun',
		thumbnail: 'assets/portfolio/fun/art-ian1.jpg',
		image: 'assets/portfolio/fun/art-ian1.jpg',
	},
	{
		subtitle: '',
		description: 'Portrait of a friend\'s daughter for her birthday card.',
		category: 'fun',
		thumbnail: 'assets/portfolio/fun/art-malaya2.jpg',
		image: 'assets/portfolio/fun/art-malaya2.jpg',
	},
	{
		subtitle: '',
		description: '',
		category: 'fun',
		thumbnail: 'assets/portfolio/fun/id-johnnyking2.jpg',
		image: 'assets/portfolio/fun/id-johnnyking2.jpg',
	},
	{
		subtitle: '',
		description: 'Bikers',
		category: 'fun',
		thumbnail: 'assets/portfolio/fun/art-bikers4.jpg',
		image: 'assets/portfolio/fun/art-bikers4.jpg',
	},
];

const portfolioFilters = [
	{
		name: 'Latest Work',
		category: 'latest-work',
	},
	{
		name: 'Packaging',
		category: 'packaging',
	},
	{
		name: 'Ads',
		category: 'ads',
	},
	{
		name: 'Point-of-Sale (POS)',
		category: 'pos',
	},
	{
		name: 'Displays',
		category: 'display',
	},
	{
		name: 'Digital',
		category: 'digital',
	},
	{
		name: 'Print',
		category: 'print',
	},
	{
		name: 'ID',
		category: 'id',
	},
	{
		name: 'Fun Stuff',
		category: 'fun',
	},
];

const data = {
	portfolio,
	portfolioFilters,
};

export default data;
