import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer(): React.ReactElement {
	const currentYear = new Date().getFullYear();

	return (
		<footer>
			<Container>
				<Row>
					<Col>
						copyright &copy; {currentYear} <a href="mailto:jerry.yeoh1270@gmail.com">Jerry Yeoh</a>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default Footer;
