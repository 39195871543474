import { Link } from "react-router-dom";
import { BoxArrowUpRight } from 'react-bootstrap-icons';

function NotFound(): React.ReactElement {
	return (
		<div className="not-found">
			<h1>Error 404</h1>
			<h3>The page you are looking for does not exist.</h3>
			<div>
				<Link to="/" className="back-to-home"><span>Back to Home</span> <BoxArrowUpRight className="icon" size={32} /></Link>
			</div>
		</div>
	)
};

export default NotFound;